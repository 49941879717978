<template>
  <div class="p-10 pr-16 all">
    <success v-if="activeSuccess" :pdf="pdf" :message="message" :activation="activeSuccess"  @oga="retourSuccess"/>
    <recap :activation="activeRecap" :momo="momo" v-if="activeRecap" @oga="retourRecap"/>
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-4/5 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retour"
          />
          <div class="ml-4 w-3/5">
            Transferts de fonds
          </div>
        </div>

        <div class="w-1/5">
          <bouton label="Enregistrer" height="54.4px" @info="continuer" />
        </div>
      </div>

      <div class="mt-10 pl-4">
        <div class="flex mt-6 justify-center bg-white rounded-10 p-6 pl-10 pr-10 text-c20">
          MOBILE MONEY
        </div>
      </div>

      <div class="mt-10 pl-4">
        <mobile @info="retourMomo"/>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import mobile from '../../component/cdevs/transfere/mobileMoney'
import recap from '../../component/popup/recapMomo'
import success from '../../component/popup/success'

export default {
  name: "Index",

  components: {
    bouton,
    mobile,
    recap,
    success
  },

  data () {
    return {
      icons: {
        back,
      },
      activeRecap: false,
      activeSuccess: false,
      message: 'Transfert éffectué avec succes',
      momo: null,
      pdf: null
    }
  },

  created () {
  },

  methods: {
    selected (index) {
      this.position = index
    },

    retourMomo (answer) {
      this.momo = answer
    },

    continuer () {
        this.activeRecap = true
    },

    retourRecap (answer) {
      if (answer !== false) {
        this.pdf = answer
        this.activeSuccess = true
      }
      this.activeRecap = false
    },

    retourSuccess() {
      this.$router.push('/caisses')
    },

    retour () {
      window.history.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}

</style>
